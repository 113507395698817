import React from 'react';

import { Grid, Container, Typography, Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';
import serviced from '../../images/numbers/companies-serviced-bg-image.jpg';
import platform from '../../images/numbers/platform-use-bg-image.jpg';
import products from '../../images/numbers/products-bg-image.jpg';
import productsWide from '../../images/numbers/products-bg-image-wide.jpg';
import country from '../../images/numbers/countries-bg-image.jpg';
import years from '../../images/numbers/years-bg-image.jpg';
import { FC } from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#F5F9FF',
    lineHeight: '0',
    height: 'auto',
  },
  rootContainer: {
    padding: '6rem 0 16rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0 10rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0rem 0 8rem 0',
    },
  },
  header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '3.5em',
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
      marginBottom: '0px',
    },
  },

  card: {
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.1)',
    background: '#fff',
    borderRadius: '20px',
    border: '2px solid #E5EAF4',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: '110%',
    backgroundRepeat: 'no-repeat',
    transition: 'all 0.5s',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      backgroundSize: '120%',
    },
    '&:focus': {
      backgroundSize: '120%',
    },
  },

  cardNumber: {
    fontSize: '5rem',
    lineHeight: 1,
    margin: '0',
    padding: '0',

    [theme.breakpoints.down('sm')]: {
      fontSize: '4rem',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '2.75rem',
    },
  },
  cardNumber1: {
    color: '#055291',
  },
  cardNumber2: {
    color: '#2A7ABC',
  },
  cardNumber3: {
    color: '#0F95A4',
  },
  cardNumber4: {
    color: '#2A7ABC',
  },
  cardNumber5: {
    color: '#055291',
  },
  cardNumber6: {
    color: '#055291',
  },
  cardSpan: {
    color: '#002D5C',
    fontSize: '1.625rem',
    lineHeight: 1,
    textAlign: 'center',
    fontWeight: 600,

    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  box: {
    display: 'flex',
    height: '800px',
    marginTop: '2rem',
    marginBottom: '20px',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    [theme.breakpoints.down('xs')]: {
      height: '600px',
    },
  },

  left: {
    flex: '2 1 auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: '10px',

    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
  },

  box2: {
    display: 'flex',
    flexBasis: '66.6%',
    marginBottom: '10px',

    [theme.breakpoints.down('xs')]: {
      marginBottom: '5px',
    },
  },

  box2Left: {
    display: 'flex',
    flex: '1 1 auto',
    marginRight: '10px',

    [theme.breakpoints.down('xs')]: {
      marginRight: '5px',
    },
  },

  card1: {
    backgroundImage: `url(${serviced})`,
  },

  box2Right: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    marginLeft: '10px',

    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px',
    },
  },

  card2: {
    flex: '1 1 auto',
    marginBottom: '10px',
    backgroundImage: `url(${platform})`,

    [theme.breakpoints.down('xs')]: {
      marginBottom: '5px',
    },
  },

  card3: {
    flex: '1 1 auto',
    marginTop: '10px',
    backgroundImage: `url(${years})`,

    [theme.breakpoints.down('xs')]: {
      marginTop: '5px',
    },
  },

  box3: {
    display: 'flex',
    flexBasis: '33.3%',
    marginTop: '10px',

    [theme.breakpoints.down('xs')]: {
      marginTop: '5px',
    },
  },

  card4: {
    backgroundImage: `url(${country})`,
  },

  right: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '10px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  card5: {
    backgroundImage: `url(${products})`,
  },

  top: {
    flex: 'auto',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },

    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px',
    },
  },

  card6: {
    backgroundImage: `url(${productsWide})`,
  },
}));

const ByTheNumbers: FC = () => {
  const classes = useStyles();
  const med = useMediaQuery('(max-width: 960px)');

  return (
    <div className={classes.root}>
      <WaveDownSVG height="213" width="100%" fill="white" shadow />
      <div className={classes.rootContainer}>
        <Container>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item xs={12} md={10}>
              <Typography variant="h2" className={classes.header}>
                WorkWave by the{' '}
                <span style={{ color: '#0F95A4' }}>Numbers</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <div className={classes.box}>
              <div className={classes.top}>
                <div className={`${classes.card} ${classes.card6}`}>
                  <h2 className={`${classes.cardNumber} ${classes.cardNumber6}`}> 20+ </h2>
                  <span className={classes.cardSpan} role="heading"> Products and Services</span>
                </div>
              </div>
              <div className={classes.left}>
                <div className={classes.box2}>
                  <div className={classes.box2Left}>
                    <div className={`${classes.card} ${classes.card1}`}>
                      <h2 className={`${classes.cardNumber} ${classes.cardNumber1}`}> 8,000 </h2>
                      <span className={classes.cardSpan} role="heading"> Companies Served</span>
                    </div>
                  </div>
                  <div className={classes.box2Right}>
                    <div className={`${classes.card} ${classes.card2}`}>
                      <span className={classes.cardSpan}> Over</span>
                      <h2 className={`${classes.cardNumber} ${classes.cardNumber2}`}> 350k+ </h2>
                      <span className={classes.cardSpan} role="heading">
                        Service Professionals<br></br>
                        Using our Platforms
                      </span>
                    </div>
                    <div className={`${classes.card} ${classes.card3}`}>
                      <h2 className={`${classes.cardNumber} ${classes.cardNumber3}`}> 40</h2>
                      <span className={classes.cardSpan} role="heading">
                        {' '}
                        Years Serving the<br></br>
                        Field Service Industry
                      </span>
                    </div>
                  </div>
                </div>
                <div className={classes.box3}>
                  <div className={`${classes.card} ${classes.card4}`}>
                    <h2 className={`${classes.cardNumber} ${classes.cardNumber4}`}> 900 </h2>
                    <span className={classes.cardSpan} role="heading"> Employees Across 7 Countries</span>
                  </div>
                </div>
              </div>
              <div className={classes.right}>
                <div className={`${classes.card} ${classes.card5}`}>
                  <h2 className={`${classes.cardNumber} ${classes.cardNumber5}`}> 20+ </h2>
                  <span className={classes.cardSpan} role="heading"> Products and Services</span>
                </div>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default ByTheNumbers;
