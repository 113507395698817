import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Grid, Container, Typography, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';
import { CenterFeatures } from './CenterFeatures';

import { values } from './values';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    background: '#f5f9ff',
    borderTop: '10px solid #fff',
    borderBottom: '10px solid #fff',
  },

  background: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    background: 'transparent',
    position: 'absolute',
    zIndex: 1,
    top: '0',
    padding: '1rem 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  particle: {
    width: '20vmin',
    height: '20vmin',
    borderRadius: '20vmin',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    animationName: '$move',
    animationDuration: '6s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    opacity: '0.4',
    zIndex: 2,
  },
  '@keyframes move': {
    '100%': {
      transform: 'translate3d(0, 0, 1px) rotate(360deg)',
    },
  },

  centerFeatures: {
    background: 'transparent',
    position: 'relative',
    zIndex: 3,

    [theme.breakpoints.down('sm')]: {
      padding: '6rem 0 8rem 0',
    },
  },
}));

const CoreValues = () => {
  const classes = useStyles();

  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(values[0]);
  }, []);

  const med = useMediaQuery('(max-width: 960px)');
  return (
    <div className={classes.root}>
       <div className={classes.background}>
          {[...Array(20)].map((_, index) => (
            <span
              key={index}
              className={classes.particle}
              style={{
                color:
                  index === 0
                    ? '#002D5C'
                    : index === 1
                    ? '#2A7ABC'
                    : index === 2
                    ? '#002D5C'
                    : index === 3
                    ? '#002D5C'
                    : index === 4
                    ? '#0F95A4'
                    : index === 5
                    ? '#002D5C'
                    : index === 6
                    ? '#0F95A4'
                    : index === 7
                    ? '#2A7ABC'
                    : index === 8
                    ? '#002D5C'
                    : index === 9
                    ? '#2A7ABC'
                    : index === 10
                    ? '#0F95A4'
                    : index === 11
                    ? '#0F95A4'
                    : index === 12
                    ? '#2A7ABC'
                    : index === 13
                    ? '#0F95A4'
                    : index === 14
                    ? '#0F95A4'
                    : index === 15
                    ? '#002D5C'
                    : index === 16
                    ? '#002D5C'
                    : index === 17
                    ? '#0F95A4'
                    : index === 18
                    ? '#0F95A4'
                    : '#0F95A4', // For index === 19
                top:
                  index === 0
                    ? '81%'
                    : index === 1
                    ? '71%'
                    : index === 2
                    ? '8%'
                    : index === 3
                    ? '43%'
                    : index === 4
                    ? '8%'
                    : index === 5
                    ? '5%'
                    : index === 6
                    ? '68%'
                    : index === 7
                    ? '18%'
                    : index === 8
                    ? '100%'
                    : index === 9
                    ? '94%'
                    : index === 10
                    ? '17%'
                    : index === 11
                    ? '68%'
                    : index === 12
                    ? '46%'
                    : index === 13
                    ? '49%'
                    : index === 14
                    ? '17%'
                    : index === 15
                    ? '4%'
                    : index === 16
                    ? '4%'
                    : index === 17
                    ? '69%'
                    : index === 18
                    ? '86%'
                    : '32%', // For index === 19
                left:
                  index === 0
                    ? '21%'
                    : index === 1
                    ? '32%'
                    : index === 2
                    ? '1%'
                    : index === 3
                    ? '85%'
                    : index === 4
                    ? '93%'
                    : index === 5
                    ? '66%'
                    : index === 6
                    ? '31%'
                    : index === 7
                    ? '25%'
                    : index === 8
                    ? '55%'
                    : index === 9
                    ? '84%'
                    : index === 10
                    ? '41%'
                    : index === 11
                    ? '64%'
                    : index === 12
                    ? '55%'
                    : index === 13
                    ? '45%'
                    : index === 14
                    ? '2%'
                    : index === 15
                    ? '50%'
                    : index === 16
                    ? '21%'
                    : index === 17
                    ? '90%'
                    : index === 18
                    ? '93%'
                    : '38%', // For index === 19
                animationDuration:
                  index === 0
                    ? '14.6s'
                    : index === 1
                    ? '14.2s'
                    : index === 2
                    ? '15.6s'
                    : index === 3
                    ? '10.4s'
                    : index === 4
                    ? '11.9s'
                    : index === 5
                    ? '11.8s'
                    : index === 6
                    ? '12.3s'
                    : index === 7
                    ? '15.6s'
                    : index === 8
                    ? '10.5s'
                    : index === 9
                    ? '10.2s'
                    : index === 10
                    ? '10.2s'
                    : index === 11
                    ? '14.8s'
                    : index === 12
                    ? '12.1s'
                    : index === 13
                    ? '11.1s'
                    : index === 14
                    ? '13.8s'
                    : index === 15
                    ? '15.8s'
                    : index === 16
                    ? '15.5s'
                    : index === 17
                    ? '12.8s'
                    : index === 18
                    ? '10.1s'
                    : '11.3s', // For index === 19
                animationDelay:
                  index === 0
                    ? '-4.9s'
                    : index === 1
                    ? '-14.7s'
                    : index === 2
                    ? '-0.9s'
                    : index === 3
                    ? '-14s'
                    : index === 4
                    ? '-3.2s'
                    : index === 5
                    ? '-10.4s'
                    : index === 6
                    ? '-14.9s'
                    : index === 7
                    ? '-2.8s'
                    : index === 8
                    ? '-3.5s'
                    : index === 9
                    ? '-14s'
                    : index === 10
                    ? '-11s'
                    : index === 11
                    ? '-9.6s'
                    : index === 12
                    ? '-3.8s'
                    : index === 13
                    ? '-0.8s'
                    : index === 14
                    ? '-10.3s'
                    : index === 15
                    ? '-11.5s'
                    : index === 16
                    ? '-13.7s'
                    : index === 17
                    ? '-11s'
                    : index === 18
                    ? '-13.6s'
                    : '-3.5s', // For index === 19
                transformOrigin:
                  index === 0
                    ? '19vw -5vh'
                    : index === 1
                    ? '25vw -12vh'
                    : index === 2
                    ? '6vw -17vh'
                    : index === 3
                    ? '24vw -8vh'
                    : index === 4
                    ? '-12vw 21vh'
                    : index === 5
                    ? '7vw 1vh'
                    : index === 6
                    ? '-15vw -17vh'
                    : index === 7
                    ? '-10vw -24vh'
                    : index === 8
                    ? '19vw 4vh'
                    : index === 9
                    ? '16vw -19vh'
                    : index === 10
                    ? '-5vw -4vh'
                    : index === 11
                    ? '-5vw -3vh'
                    : index === 12
                    ? '5vw -1vh'
                    : index === 13
                    ? '-3vw 1vh'
                    : index === 14
                    ? '20vw 14vh'
                    : index === 15
                    ? '-7vw 0vh'
                    : index === 16
                    ? '24vw -7vh'
                    : index === 17
                    ? '-3vw 8vh'
                    : index === 18
                    ? '20vw -17vh'
                    : '-13vw 6vh', // For index === 19
                boxShadow:
                  index === 0
                    ? '40vmin 0 7.9315459128vmin currentColor'
                    : index === 1
                    ? '40vmin 0 7.0571905772vmin currentColor'
                    : index === 2
                    ? '-40vmin 0 8.4662376393vmin currentColor'
                    : index === 3
                    ? '40vmin 0 13.5424774841vmin currentColor'
                    : index === 4
                    ? '-40vmin 0 11.4299672152vmin currentColor'
                    : index === 5
                    ? '-40vmin 0 8.6003297514vmin currentColor'
                    : index === 6
                    ? '40vmin 0 13.0255744955vmin currentColor'
                    : index === 7
                    ? '40vmin 0 6.1926332896vmin currentColor'
                    : index === 8
                    ? '-40vmin 0 13.8344248213vmin currentColor'
                    : index === 9
                    ? '40vmin 0 5.1723346767vmin currentColor'
                    : index === 10
                    ? '-40vmin 0 11.81587529vmin currentColor'
                    : index === 11
                    ? '-40vmin 0 14.4282841494vmin currentColor'
                    : index === 12
                    ? '-40vmin 0 7.2866485617vmin currentColor'
                    : index === 13
                    ? '40vmin 0 14.9636388714vmin currentColor'
                    : index === 14
                    ? '40vmin 0 9.0470197942vmin currentColor'
                    : index === 15
                    ? '-40vmin 0 10.933240287vmin currentColor'
                    : index === 16
                    ? '-40vmin 0 13.1281644024vmin currentColor'
                    : index === 17
                    ? '40vmin 0 7.6827759204vmin currentColor'
                    : '-40vmin 0 7.297196359vmin currentColor', // For index === 19
              }}
            />
          ))}
        </div>
      <WaveDownSVG fill="#FFF" shadow style={{ zIndex: 99}} />
      <div className={classes.centerFeatures}>
        <CenterFeatures
          values={values}
          setSelected={setSelected}
          selected={selected}
          style={{ position: 'relative', zIndex: 3 }}
        />
       
      </div>
      <WaveUpSVG fill="#FFF" shadow style={{ zIndex: 99}} />
    </div>
  );
};

export default CoreValues;
