import React from 'react';

import { useMediaQuery } from '@material-ui/core';

import USBenefits from './Tabs/usbenefits';
import ItalyBenefits from './Tabs/italybenefits';
import UKBenefits from './Tabs/ukbenefits';
import AUSBenefits from './Tabs/ausbenefits';

export const PerksBody = ({ value }) => {
  const med = useMediaQuery('(max-width: 960px)');

  const displayPanel = (value) => {
    switch (value) {
      case 0:
        return <USBenefits />;
      case 1:
        return <UKBenefits />;
      case 2:
        return <ItalyBenefits />;
      case 3:
        return <AUSBenefits />;
    }
  };

  return <>{displayPanel(value)}</>;
};
