import React from 'react';

import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from '@material-ui/core';

import { PerksBody } from './PerksBody';

import USicon from '../../svgs/perks/usa-icon.svg';
import Italyicon from '../../svgs/perks/italy-icon.svg';
import UKicon from '../../svgs/perks/uk-icon.svg';
import AUSicon from '../../svgs/perks/aus-icon.svg';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    color: theme.workwaveBlue,
    marginLeft: '1.2rem',
    fontSize: '1.3rem',
    [theme.breakpoints.down('xs')]: {
      width: '75%',
      lineHeight: 1.1,
    },
  },
  card: {
    // border: '1px solid #ECECEE',
    boxShadow: '0px .5px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    cursor: 'pointer',
    background: theme.white,
    transition: 'transform 0.25s, box-shadow 0.75s',
  },
  root: {
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
      minHeight: 'unset',
    },
  },
  expanded: {
    margin: 0,
    minHeight: 'unset',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    width: '60px',
    borderRadius: '8px 0 0 8px',
    border: `1px solid #E5EAF4`,
    background: '#E5EAF4',
    padding: '10px',
  },
}));

export const MobilePerksCard = ({ ribbon, perksRef, index, value, handleChange }) => {
  const classes = useStyles();

  const displayImage = (index) => {
    switch (index) {
      case 0:
        return <USicon />;
      case 1:
        return <Italyicon />;
      case 2:
        return <UKicon />;
      case 3:
        return <AUSicon />;
    }
  };

  return (
    <Grid item container xs={12}>
      <Accordion
        expanded={value === index ? true : false}
        className={classes.card}
        onChange={() => handleChange(index)}
        role="button"
        tabIndex={0}
        style={{
          borderRadius: '8px',
          border: '1px solid #E5EAF4',
          boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
          width: '100%',
        }}
      >
        <AccordionSummary
          classes={{
            content: classes.content,
            expanded: classes.expanded,
            root: classes.root,
          }}
          style={{ padding: 0, marginTop: '0 !important' }}
        >
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <div className={classes.icon} role="button" tabIndex={0}>{displayImage(index)}</div>
            <Typography variant="h5" className={classes.title} role="button">
              {ribbon.ribbonTitle}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <PerksBody value={value} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
