import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';

import { Grid, Container, Typography, Button, Card, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { LocationCard } from './LocationCard';
import { FC } from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '0',
    marginBottom: '0',
  },
  header: {
    fontSize: '2.5rem',
    fontWeight: 700,
    paddingBottom: '10px',
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      marginTop: '2rem',
      fontSize: '1.9rem',
    },
  },
  subHeader: {
    fontFamily: 'Roboto',
    //fontSize: '1.375rem',
    color: '#4B5B69',
    textAlign: 'center',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {},
  },
  cta: {
    fontSize: '20px',
    margin: '1rem 0 4rem 0',
    color: '#ffffff',
    [theme.breakpoints.down('lg')]: {
      padding: '12px 20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },

  ctaContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {},
  },
  locationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0 8rem 0',

    [theme.breakpoints.down('md')]: {
      margin: '1rem 0 4rem 0',
    },
  },

  locationGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },

  locationCard: {
    color: '#002D5C',
    background: '#fff',
    padding: '20px 0',
    borderRadius: '20px',
    height: 'auto',
    width: '380px',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    margin: '10px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      width: '280px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'start',
      alignItems: 'start',
      padding: '20px',
      margin: '10px 0',
    },
  },
  cardHeader: {
    textAlign: 'center',
    marginBottom: '0.75rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  cardAddress: {
    textAlign: 'center',
    fontSize: '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },

  blueBackground: {
    paddingTop: '4rem',
    paddingBottom: '4rem',
    backgroundColor: '#002D5C',
    borderTop: '12px solid',
    animation: '$colorTransition 8s infinite',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('xs')]: {},
  },

  '@keyframes colorTransition': {
    '0%': { borderTopColor: '#002D5C' },
    '25%': { borderTopColor: '#2A7ABC' },
    '50%': { borderTopColor: '#055291' },
    '75%': { borderTopColor: '#0F95A4' },
    '100%': { borderTopColor: '#002D5C' },
  },

  whiteText: {
    color: '#ffffff',
    fontFamily: ['Roboto', 'sans-serif'].join(),
  },
}));
const Locations: FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Container>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" className={classes.header}>
                Ready to reach your <span style={{ color: '#2A7ABC' }}>Full Potential?</span>
              </Typography>
              <Typography variant="h4" className={classes.subHeader}>
                View our open positions and apply today
              </Typography>
              <Grid container className={classes.ctaContainer}>
                <a href="https://jobs.lever.co/workwave" target="_blank" aria-label="Opens in a new tab" rel="noreferrer">
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    className={classes.cta}
                  >
                    Browse Jobs
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
          >
            <Grid item>
              <Typography variant="h2" color="primary" className={classes.header}>
                Our Locations
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="column" justifyContent="center" alignItems="center">
            <div className={classes.locationContainer}>
              <div className={classes.locationGroup}>
                <div
                  data-sal="slide-down"
                  data-sal-duration="200"
                    data-sal-delay="0"
                  data-sal-easing=""
                  className={classes.locationCard}
                  style={{ borderTop: '12px solid #0F95A4' }}
                >
                  <Grid item>
                    <Typography variant="h3" color="primary" className={classes.cardHeader}>
                      Michigan
                    </Typography>

                    <Typography variant="body1" className={classes.cardAddress}>
                      4375 Pineview Drive,<br></br>
                      Walled Lake, MI 48390, <br></br> USA
                    </Typography>
                  </Grid>
                </div>
                <div
                  data-sal="slide-down"
                  data-sal-duration="200"
                    data-sal-delay="0"
                  data-sal-easing=""
                  className={classes.locationCard}
                  style={{ borderTop: '12px solid #055291' }}
                >
                  <Grid item>
                    <Typography variant="h3" color="primary" className={classes.cardHeader}>
                      Nebraska
                    </Typography>

                    <Typography variant="body1" className={classes.cardAddress}>
                      407 S. 27th Avenue,<br></br>
                      Omaha, NE 68131, <br></br> USA
                    </Typography>
                  </Grid>
                </div>
              </div>
              <div className={classes.locationGroup}>
                <div
                  data-sal="slide-right"
                  data-sal-duration="200"
                    data-sal-delay="0"
                  data-sal-easing=""
                  className={classes.locationCard}
                  style={{ borderTop: '12px solid #2A7ABC' }}
                >
                  <Grid item>
                    <Typography variant="h3" color="primary" className={classes.cardHeader}>
                      North Carolina
                    </Typography>

                    <Typography variant="body1" className={classes.cardAddress}>
                      1001 Wade Avenue, Suite 215<br></br>
                      Raleigh, NC 27605,
                      <br></br> USA
                    </Typography>
                  </Grid>
                </div>
                <div
                  data-sal="fade"
                  data-sal-duration="200"
                    data-sal-delay="0"
                  data-sal-easing=""
                  className={classes.locationCard}
                  style={{ borderTop: '12px solid #002D5C' }}
                >
                  <Grid item>
                    <Typography variant="h3" color="primary" className={classes.cardHeader}>
                      US Headquarters
                    </Typography>

                    <Typography variant="body1" className={classes.cardAddress}>
                      101 Crawfords Corner Road<br></br>
                      Suite 2511-W, Holmdel, NJ 07733, <br></br> USA
                    </Typography>
                  </Grid>
                </div>
                <div
                  data-sal="slide-left"
                  data-sal-duration="200"
                    data-sal-delay="0"
                  data-sal-easing=""
                  className={classes.locationCard}
                  style={{ borderTop: '12px solid #2176B4' }}
                >
                  <Grid item>
                    <Typography variant="h3" color="primary" className={classes.cardHeader}>
                      United Kingdom
                    </Typography>

                    <Typography variant="body1" className={classes.cardAddress}>
                      1st Floor, Unit 1, Waterfront Business Park<br></br>
                      Dudley Road, Brierley Hill, West Midlands<br></br>
                      DY5 1LX, United Kingdom<br></br>
                    </Typography>
                  </Grid>
                </div>
              </div>
              <div className={classes.locationGroup}>
                <div
                  data-sal="slide-up"
                  data-sal-duration="200"
                    data-sal-delay="0"
                  data-sal-easing=""
                  className={classes.locationCard}
                  style={{ borderTop: '12px solid #055291' }}
                >
                  <Grid item>
                    <Typography variant="h3" color="primary" className={classes.cardHeader}>
                      Italy
                    </Typography>

                    <Typography variant="body1" className={classes.cardAddress}>
                      Via Carmelitani Scalzi 20<br></br>
                      37122 Verona,<br></br>
                      Italy
                    </Typography>
                  </Grid>
                </div>
                <div
                  data-sal="slide-up"
                  data-sal-duration="200"
                    data-sal-delay="0"
                  data-sal-easing=""
                  className={classes.locationCard}
                  style={{ borderTop: '12px solid #0F95A4' }}
                >
                  <Grid item>
                    <Typography variant="h3" color="primary" className={classes.cardHeader}>
                      Australia
                    </Typography>

                    <Typography variant="body1" className={classes.cardAddress}>
                      19 Budd Street<br></br>
                      Collingwood VIC 3066,<br></br>
                      Australia
                    </Typography>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Container>
      </div>
      <div className={classes.blueBackground}>
        <Container>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.whiteText}>
                We’re an equal-opportunity employer. All applicants will be considered for
                employment without attention to race, color, age, religion, sex, sexual orientation,
                gender identity, national origin, veteran or disability status: Don't meet every
                single requirement? Studies have shown that women and people of color are less
                likely to apply for jobs unless they meet every single qualification. At WorkWave,
                we are dedicated to building a diverse, inclusive and authentic workplace, so if you
                feel like you could make a great impact in this role but your past experience
                doesn't align perfectly with every qualification in the job description,{' '}
                <b>
                  <i>we encourage you to apply anyway</i>
                </b>
                . You may just be the right candidate for this or other roles!
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Locations;
