import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';

import { Grid, Container, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import 'animate.css/animate.min.css';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0rem 0 0rem 0',
  },
  header: {
    fontSize: '2.5rem',
    fontWeight: 700,
    paddingBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },
  subHeader: {
    fontFamily: 'Roboto',
    //fontSize: '1.375rem',
    color: '#4B5B69',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px',
    },
  },
  itemContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginLeft: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
    // marginTop: '40px',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '40px',
    zIndex: 2,

    [theme.breakpoints.down('md')]: {
      marginBottom: '30px',
    },

    '&:last-child': {
      marginBottom: '0px',
    },
  },

  text: {
    margin: 0,
    //fontFamily: ['Roboto', 'sans-serif'].join(),
  },
  line: {
    height: '96%',
    width: '10px',
    position: 'absolute',
    zIndex: 1,
    left: '35px',
    padding: '54px 0',
    borderRadius: '10px',
  },
  span: {
    height: '100%',
    width: '10px',
    display: 'block',
    position: 'relative',
    background:
      'linear-gradient( 180deg,#0f95a4 7.52%, #2a7abc 17.12%, #2a7abc 31.94%, #055291 42.15%, #055291 56.85%, #002d5c 66.57%, #002d5c 81.51%, #001933 91.11%)',
    zIndex: 1,
  },
  item1Circle: {
    background: '#0f95a4',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  item2Circle: {
    background: '#2a7abc',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  item3Circle: {
    background: '#055291',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  item4Circle: {
    background: '#002d5c',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  item5Circle: {
    background: '#001933',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginRight: '15px',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5EAF4',
  },
  imgCont: {
    alignItems: 'start',
    justifyContent: 'start',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
  interviewImage: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const Interview = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid
              item
              xs={12}
              md={6}
              justifyContent="center"
              alignItems="center"
              container
              className={classes.imgCont}
            >
              <div>
                <Typography variant="h2" color="primary" className={classes.header}>
                  What can I <span style={{ color: '#0F95A4' }}>Expect</span> when I Interview with
                  WorkWave?
                </Typography>
                <Typography variant="body1" className={classes.subHeader}>
                  No two jobs or teams interview the same, each process is tailored to accommodate
                  the unique aspects of each team and position.
                </Typography>

                <Typography
                  variant="body1"
                  className={classes.subHeader}
                  style={{ fontWeight: 700, color: '#002D5C' }}
                >
                  Join the Conversation! Here's an Example of our Interviewing Style:
                </Typography>
              </div>
              <StaticImage
                className={classes.interviewImage}
                src="../../images/interview/full-potential-logo.png"
                alt="Reach Your FULL Potential"
                quality={100}
                width={550}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid className={classes.itemContainer}>
                <div className={classes.line}>
                  <span className={classes.span}> </span>
                </div>

                <div className={classes.item}>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                    className={classes.item1Circle}
                    style={{}}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'clipboard-list-check']}
                      style={{
                        height: '51px',
                        width: '38px',
                        padding: '10px',
                        color: '#ffffff',
                      }}
                    />
                  </div>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                  >
                    <Typography variant="h5" color="primary" className={classes.text}>
                      Phone Call{' '}
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                      If your application aligns closely with our current needs, a member of our
                      Talent Acquisition team will reach out to you for a brief call to learn more.
                    </Typography>
                  </div>
                </div>

                <div className={classes.item}>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                    className={classes.item2Circle}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'camera-web']}
                      style={{
                        height: '46px',
                        width: '40px',
                        padding: '10px',
                        color: '#ffffff',
                      }}
                    />
                  </div>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                  >
                    <Typography variant="h5" color="primary" className={classes.text}>
                      Virtual Interview
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                      Virtual one-on-one interview with the hiring manager.
                    </Typography>
                  </div>
                </div>
                <div className={classes.item}>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                    className={classes.item3Circle}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'people-group']}
                      style={{
                        height: '45px',
                        width: '56.74px',
                        padding: '10px',
                        color: '#ffffff',
                      }}
                    />
                  </div>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                  >
                    <Typography variant="h5" color="primary" className={classes.text}>
                      Meet the Team
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                      Get ready to mingle with some of the awesome folks you'll be working with and
                      decide if we're your kind of people. For some regional offices, you may get
                      the opportunity to meet our teams in person!
                    </Typography>
                  </div>
                </div>
                <div className={classes.item}>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                    className={classes.item4Circle}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'face-grin-wide']}
                      style={{
                        height: '46px',
                        width: '46px',
                        padding: '10px',
                        color: '#ffffff',
                      }}
                    />
                  </div>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                  >
                    <Typography variant="h5" color="primary" className={classes.text}>
                      Just be yourself!
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                      As a final step, completing a personality assessment is another way for us to
                      get to know you better and give us insight into what makes you tick.
                    </Typography>
                  </div>
                </div>
                <div className={classes.item}>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                    className={classes.item5Circle}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'handshake-angle']}
                      style={{
                        height: '45px',
                        width: '56.25px',
                        padding: '10px',
                        color: '#ffffff',
                      }}
                    />
                  </div>
                  <div
                    data-sal="slide-up"
                    data-sal-duration="200"
                    data-sal-delay="100"
                    data-sal-easing=""
                  >
                    <Typography variant="h5" color="primary" className={classes.text}>
                      Offer
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                      Say "Yes" to the quest! If you've successfully completed the interview stages
                      and we determine that you're the right fit for the position, we'll extend an
                      official offer to join the team!
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
