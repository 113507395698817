import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

import USicon from '../../svgs/perks/usa-icon.svg';
import Italyicon from '../../svgs/perks/italy-icon.svg';
import UKicon from '../../svgs/perks/uk-icon.svg';
import AUSicon from '../../svgs/perks/aus-icon.svg';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: '#002d5c',
    textAlign: 'center',
    lineHeight: '1',
    margin: '0 auto',
    position: 'absolute',
    padding: '0 5px',
    left: '50px',
    right: '0',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  card: {
    // border: '1px solid #ECECEE',
    boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.10)',
    userSelect: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    background: '#ffffff',
    width: '100%',
    transition: 'transform 0.25s, box-shadow 0.75s',
    margin: '0 auto',
    position: 'relative',
    border: '2px solid #E5EAF4',
    overflow: 'hidden',
  },
  cardGrid: {
    marginTop: '30px',
    marginBottom: '60px',
    paddingRight: '16px',
    '&:first-child': {
     
    },
    '&:last-child': {
      paddingRight: '0px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '20px',
      marginTop: '0px',
      
    },
    [theme.breakpoints.down('sm')]: {
      '&:nth-child(2n)': {
        paddingRight: '0px',
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    justifyContent: 'center',
    borderRight: '2px solid #E5EAF4',
    background: '#E5EAF4',
    padding: '6px',
  },
}));

const RibbonCard = ({ handleClick, ribbon, index, value }) => {
  const classes = useStyles();
  const { ribbonTitle } = ribbon;

  const displayImage = () => {
    switch (index) {
      case 0:
        return <USicon />;
      case 1:
        return <UKicon />;
      case 2:
        return <Italyicon />;
      case 3:
        return <AUSicon />;
    }
  };

  return (
    <Grid style={{}} item container xs={6} md={3} key={index} className={classes.cardGrid}>
      <div
        className={classes.card}
        role="button"
        tabIndex={0}
        style={
          value === index
            ? {
                // border: '1px solid #ECECEE',
                // transform: 'scale(1.04)',
                transition: 'transform 0.25s, box-shadow 0.75s',
                boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.25)',
              }
            : null
        }
      >
        <Grid
          onClick={(e) => handleClick(e, index)}
          role="button"
          tabIndex={0}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <div className={classes.icon}>{displayImage(index)}</div>
          <Typography className={classes.title}>{ribbonTitle}</Typography>
        </Grid>
      </div>
    </Grid>
  );
};

export default RibbonCard;
