import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Grid, Container, Typography, useMediaQuery, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import authentic from '../../images/core-values/core-values_authentic.svg';
import difference from '../../images/core-values/core-values_difference.svg';
import embrace from '../../images/core-values/core-values_embrace.svg';
import empower from '../../images/core-values/core-values_empower.svg';
import impact from '../../images/core-values/core-values_impact.svg';

const useStyles = makeStyles((theme) => ({
  root: {},

  centerFeatures: {
    background: 'transparent',
    padding: '14rem 0 16rem',
    [theme.breakpoints.down('sm')]: {
      padding: '8rem 0 12rem 0',
    },
  },

  subHeader: {
    fontFamily: 'Roboto',
    fontSize: '1.3125rem',
    fontWeight: 500,
    textAlign: 'left',
    color: '#4B5B69',
    paddingBottom: '10px',
  },

  header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'left',
    paddingBottom: '1rem',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },

  paraContent: {
    fontFamily: ['Roboto', 'sans-serif'].join(),
    zIndex: 100,
    position: 'relative',
    textAlign: 'center',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xs')]: {},
  },

  card: {
    opacity: 1,
    background: 'white',
    marginBottom: '2rem',
    padding: '2rem',
    borderRadius: '20px',
    border: '2px solid #E5EAF4',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
  },

  cardHeader: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: '1rem',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },

  cardImg: {
    maxWidth: '280px',
    margin: '0 auto',
    display: 'block',
  },
}));

export const DesktopFeatures = ({ values, selected, setSelected }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.centerFeatures}>
        <Container>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={4}
            style={{ position: 'relative' }}
          >
            <Grid
              container
              direction="column"
              item
              xs={5}
              style={{ position: 'sticky', top: '30%', height: 'auto' }}
            >
              <Typography variant="body1" className={classes.subHeader}>
                OUR CORE VALUES
              </Typography>

              <Typography variant="h2" className={classes.header}>
                Join us and Discover Your <span style={{ color: '#2F7FC1' }}>Full Potential</span>
              </Typography>

              <Typography variant="body1" className={classes.paraContent} style={{textAlign: 'left'}}>
                At WorkWave, we celebrate uniqueness and diversity while cherishing shared 'Core
                Values' that unite us all. These principles drive our success, guiding every
                decision and shaping our vibrant culture. Together, we're creating an engaging
                workplace and a close-knit team, all fueled by these values as we reach our full
                potential.
              </Typography>

              <a href="https://jobs.lever.co/workwave" target="_blank" aria-label="Opens in a new tab" rel="noreferrer">
                <Button variant="contained" size="large" color="secondary" className={classes.cta}>
                  Browse Jobs
                </Button>
              </a>
            </Grid>

            <Grid container justifyContent="flex-start" alignItems="center" item xs={7} spacing={2}>
              <div className={classes.card} style={{ width: '100%', height: '500px' }}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Be Authentic
                </Typography>

                <Typography variant="body1" className={classes.paraContent}>
                  We believe in the act of being genuine, living and leading with transparency and
                  integrity, communicating openly and honestly, and building strong, trusting
                  foundations.
                </Typography>
                <img className={classes.cardImg} src={authentic} alt="Authentic icon" />
              </div>
              <div className={classes.card} style={{ width: '100%', height: '500px' }}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Be An Impact Player
                </Typography>

                <Typography variant="body1" className={classes.paraContent}>
                  Everyone, no matter what their position, can make significant contributions as an
                  individual, but also positively impact those around them.
                </Typography>
                <img className={classes.cardImg} src={impact} alt="Impact Player icon" />
              </div>
              <div className={classes.card} style={{ width: '100%', height: '500px' }}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Make a Difference
                </Typography>

                <Typography variant="body1" className={classes.paraContent}>
                  We are committed to going the extra mile and going beyond service and to build a
                  better and more sustainable world.
                </Typography>
                
                <img className={classes.cardImg} src={difference} alt="Make difference icon" />
              </div>
              <div className={classes.card} style={{ width: '100%', height: '500px' }}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Empower Yourself & Others
                </Typography>

                <Typography variant="body1" className={classes.paraContent}>
                  We achieve more when we collaborate and all work together, keeping people at the
                  core and our customers first.
                </Typography>
                <img className={classes.cardImg} src={empower} alt="empower others icon" />
              </div>
              <div className={classes.card} style={{ width: '100%', height: '500px' }}>
                <Typography variant="h2" className={classes.cardHeader}>
                  Embrace Opportunities
                </Typography>

                <Typography variant="body1" className={classes.paraContent}>
                  Each of us is responsible for owning our full potential and helping our company be
                  a leader in the industry.
                </Typography>
                <img className={classes.cardImg} src={embrace} alt="Embrace opportunities icon" />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
