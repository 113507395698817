import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { Grid, Button, Container, Typography, Theme } from '@material-ui/core';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/styles';

import mosaic1 from '../../images/intro/mosiac-employees/1.jpg';
import mosaic2 from '../../images/intro/mosiac-employees/2.jpg';
import mosaic3 from '../../images/intro/mosiac-employees/3.jpg';
import mosaic6 from '../../images/intro/mosiac-employees/6.jpg';
import mosaic8 from '../../images/intro/mosiac-employees/8.jpg';
import mosaic9 from '../../images/intro/mosiac-employees/9.jpg';
import mosaic10 from '../../images/intro/mosiac-employees/10.jpg';
import mosaic11 from '../../images/intro/mosiac-employees/11.jpg';
import mosaic12 from '../../images/intro/mosiac-employees/12.jpg';
import mosaic13 from '../../images/intro/mosiac-employees/13.jpg';

import mosaic16 from '../../images/intro/mosiac-employees/16.jpg';
import mosaic17 from '../../images/intro/mosiac-employees/17.jpg';
import mosaic18 from '../../images/intro/mosiac-employees/18.jpg';
import mosaic19 from '../../images/intro/mosiac-employees/19.jpg';
import mosaic20 from '../../images/intro/mosiac-employees/20.jpg';

import mosaic22 from '../../images/intro/mosiac-employees/22.jpg';

import mosaic24 from '../../images/intro/mosiac-employees/24.jpg';
import mosaic25 from '../../images/intro/mosiac-employees/25.jpg';

import mosaic27 from '../../images/intro/mosiac-employees/27.jpg';
import mosaic28 from '../../images/intro/mosiac-employees/28.jpg';
import mosaic29 from '../../images/intro/mosiac-employees/29.jpg';

import mosaic31 from '../../images/intro/mosiac-employees/31.jpg';
import mosaic32 from '../../images/intro/mosiac-employees/32.jpg';
import mosaic33 from '../../images/intro/mosiac-employees/33.jpg';

import { FC } from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
  intro: {
    background: '#fff',
    position: 'relative',
    width: '100%',
  },

  sectionHeader: {
    fontWeight: 700,
    paddingBottom: '4rem',
    textAlign: 'center',
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
      paddingBottom: '2rem',
    },
  },
  header: {
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: 1.0,
    paddingBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
      paddingTop: '0rem',
    },
  },
  content: {
    fontFamily: 'Roboto',
    fontSize: '1.375rem',
    fontWeight: 400,
  },
  rideWave: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '1.625rem',
    marginTop: '16px',
  },
  collageImage: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
  },

  tileContainer: {
    display: 'grid',
    position: 'relative',
    zIndex: 3,
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridGap: '.7rem',
    maxWidth: '550px',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      gridGap: '0.5rem',
    },
  },

  tile: {
    borderRadius: '8px',
    overflow: 'hidden',
    border: 'none',
    boxShadow: '0 0px 10px 4px rgba(0, 0, 0, 0.10)',
    display: 'flex',
  },

  tileBig: {
    background: '#002d5c',
    animation: '$colorChange 10s infinite',
  },
  '@keyframes colorChange': {
    '0%': { background: '#002d5c' },
    '25%': { background: '#055291' },
    '50%': { background: '#2A7ABC' },
    '75%': { background: '#0F95A4' },
    '100%': { background: '#002d5c' },
  },

  tileImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  iconTile: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  svgIcon: {
    width: '30%',
    color: '#fff',
    fill: '#eee',
  },
  span1: {
    gridColumn: 'span 1',
  },
  span2: {
    gridColumn: 'span 2',
    gridRow: 'span 2',
    background: '#002d5c',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  workWaveLogoSvg: {
    width: '80%',
    height: 'auto',
  },
}));

const Intro: FC = () => {
  const classes = useStyles();
  return (
    <div style={{ background: '#fff', position: 'relative', width: '100%' }}>
      <Container>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} md={10}>
            <Typography
              variant="h3"
              component="h2"
              color="primary"
              className={classes.sectionHeader}
            >
              Are you ready to Reach{' '}
              <span style={{ color: '#2F7FC1' }}>
                <span style={{ fontStyle: 'italic' }}>YOUR</span> Full Potential?
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <div className={classes.tileContainer}>
              {/* First Row   */}
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic1} alt="person 1" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic2} alt="person 2" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic3} alt="person 13" />
              </div>
              <div className={classes.tile}>
                <div
                  className={classes.iconTile}
                  style={{
                    background: '#0F95A4',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    title="heart"
                    style={{
                      width: '50%',
                      height: 'auto',
                      color: '#fff',
                    }}
                    icon={['fad', 'square-heart']}
                  />
                </div>
              </div>
              <div className={classes.tile}>
                <div
                  className={classes.iconTile}
                  style={{
                    background: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    title="heart"
                    style={{
                      width: '50%',
                      height: 'auto',
                      color: '#2A7ABC',
                    }}
                    icon={['fad', 'thumbs-up']}
                  />
                </div>
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic6} alt="person 4" />
              </div>
              {/* Second Row   */}
              <div className={classes.tile}>
                <div
                  className={classes.iconTile}
                  style={{
                    background: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    title="heart"
                    style={{
                      width: '50%',
                      height: 'auto',
                      color: '#0F95A4',
                    }}
                    icon={['fad', 'seedling']}
                  />
                </div>
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic8} alt="person 5" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic9} alt="person 6" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic10} alt="person 7" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic11} alt="person 8" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic12} alt="person 9" />
              </div>
              {/* Third Row   */}
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic13} alt="person 10" />
              </div>
              <div className={classes.tile}>
                <div
                  className={classes.iconTile}
                  style={{
                    background: '#055291',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    title="heart"
                    style={{
                      width: '50%',
                      height: 'auto',
                      color: '#fff',
                    }}
                    icon={['fad', 'face-smile-beam']}
                  />
                </div>
              </div>
              <div
                className={`${classes.tile} ${classes.tileBig}`}
                style={{
                  gridColumn: 'span 2',
                  gridRow: 'span 2',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <svg
                  className={classes.workWaveLogoSvg}
                  role="presentation"
                  width="100%"
                  height="100%"
                  viewBox="0 0 37 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.1389 8.11527L22.1412 5.10407C21.9952 4.55001 21.6059 4.09231 21.0706 3.85141C20.1216 3.44189 19.0266 3.85141 18.5886 4.79091L14.3302 14.8845L12.8702 10.6688L12.8459 10.6206C12.6756 10.187 12.2376 9.72928 11.6536 9.922C11.1669 10.0665 10.8992 10.5242 10.8019 10.7892L9.75555 13.2945L12.3106 20.931C12.6269 21.9186 13.6732 22.4486 14.6466 22.1595C15.1576 21.9909 15.5956 21.6296 15.8146 21.1478L20.1459 10.9579L21.1679 8.5248C21.2896 8.25981 21.5572 7.80211 22.0196 7.65757C22.6522 7.46485 23.0902 7.94665 23.2362 8.40435L23.1389 8.11527Z"
                    fill="#7ABC32"
                  />
                  <path
                    d="M1.79861 11.271C2.62595 11.4396 3.42895 10.9096 3.59928 10.0906C3.76961 9.27151 3.23428 8.47656 2.40695 8.30793C1.57961 8.1393 0.776615 8.66927 0.606281 9.48832C0.435948 10.3074 0.971281 11.1023 1.79861 11.271Z"
                    fill="white"
                  />
                  <path
                    d="M5.27835 13.8004C5.25402 13.7041 5.22968 13.6318 5.20535 13.5355L5.05935 13.0055C4.86468 12.0419 3.89135 11.4156 2.91802 11.6324C1.94468 11.8251 1.31202 12.7887 1.53102 13.7523C1.55535 13.8486 1.57968 13.9209 1.60402 14.0172L1.75002 14.5472C1.94468 15.5108 2.91802 16.1371 3.89135 15.9203C4.86468 15.7035 5.47302 14.764 5.27835 13.8004Z"
                    fill="white"
                  />
                  <path
                    d="M35.3298 0.599305C33.8211 -0.123384 32.8964 1.46653 32.8964 1.46653L26.1804 17.2212C26.1804 17.2212 23.3334 8.59706 23.2118 8.30799L23.1874 8.25981L23.2361 8.40435C23.2361 8.40435 23.2361 8.38026 23.2118 8.38026L23.1388 8.11527L22.1411 5.10407C21.9951 4.55 21.6058 4.0923 21.0704 3.85141C20.1214 3.44188 19.0264 3.85141 18.5884 4.7909L14.3301 14.8845L12.8701 10.6688L12.8458 10.6206C12.8458 10.6206 12.8458 10.5965 12.8214 10.5965L12.6511 10.0665L11.7021 7.29622L11.6778 7.22395C11.5074 6.79034 11.1424 6.429 10.7044 6.23628C9.75543 5.82676 8.66043 6.23628 8.22243 7.17578L6.10543 12.7164L6.0811 12.885C5.66743 13.7763 6.0811 14.8363 7.00577 15.2458C7.73577 15.559 8.58743 15.3663 9.09843 14.7881C9.2201 14.6436 9.31743 14.499 9.39043 14.3304L9.8041 13.3427L12.3348 20.9791C12.6511 21.9668 13.6974 22.4968 14.6708 22.2077C15.1818 22.0391 15.6198 21.6777 15.8388 21.1959L20.1458 10.9578L24.2824 23.364C24.5501 24.0144 25.1828 24.448 25.9128 24.4721C27.1781 24.5444 27.7134 23.364 27.7134 23.364L36.3518 2.83964C36.3518 2.86373 36.8384 1.34608 35.3298 0.599305ZM21.0704 8.76569L21.1678 8.54888L21.0704 8.76569Z"
                    fill="white"
                  />
                  <path
                    d="M6.78702 15.9202C5.88668 15.5348 4.81602 15.9202 4.42668 16.8356L3.86702 18.0883C3.50202 18.9796 3.89135 19.9914 4.76735 20.4009C5.66768 20.8104 6.73835 20.425 7.15202 19.5337L7.63868 18.4497C8.02802 17.5342 7.68735 16.3057 6.78702 15.9202Z"
                    fill="white"
                  />
                  <path
                    opacity="0.7"
                    d="M14.3545 14.8845L12.8945 10.6688L12.8702 10.6206C12.6998 10.187 12.2618 9.72931 11.6778 9.92203C11.1911 10.0666 10.9235 10.5243 10.8261 10.7893L9.77982 13.2946L12.3348 20.931C12.6512 21.9187 13.6975 22.4486 14.6708 22.1596C15.1818 21.9909 15.6198 21.6296 15.8388 21.1478L16.2282 20.2324L14.3545 14.8845Z"
                    fill="url(#paint0_linear_1_14)"
                  />
                  <path
                    opacity="0.7"
                    d="M26.1808 17.1971L25.6941 15.7036L23.2608 8.40442C23.1148 7.94672 22.6768 7.48902 22.0441 7.65764C21.5574 7.80218 21.2898 8.25988 21.1924 8.52487L20.1461 10.9579V10.982L24.2828 23.3882C24.5504 24.0386 25.1831 24.4722 25.9131 24.4963C27.1784 24.5686 27.7138 23.3882 27.7138 23.3882L28.0058 22.7137L26.1808 17.1971Z"
                    fill="url(#paint1_linear_1_14)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1_14"
                      x1="10.8369"
                      y1="14.9362"
                      x2="8.57346"
                      y2="13.6687"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#4D4A4A" stopOpacity="0" />
                      <stop offset="1" stopColor="#221F1F" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1_14"
                      x1="20.4932"
                      y1="14.1302"
                      x2="16.7758"
                      y2="12.0472"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#221F1F" stopOpacity="0" />
                      <stop offset="0.02" stopColor="#221F1F" stopOpacity="0.04" />
                      <stop offset="0.19" stopColor="#221F1F" stopOpacity="0.31" />
                      <stop offset="0.36" stopColor="#221F1F" stopOpacity="0.53" />
                      <stop offset="0.53" stopColor="#221F1F" stopOpacity="0.71" />
                      <stop offset="0.69" stopColor="#221F1F" stopOpacity="0.83" />
                      <stop offset="0.85" stopColor="#221F1F" stopOpacity="0.9" />
                      <stop offset="1" stopColor="#221F1F" stopOpacity="0.93" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic16} alt="person 11" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic17} alt="person 12" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic18} alt="person 13" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic19} alt="person 14" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic20} alt="person 15" />
              </div>

              <div className={classes.tile}>
                <div
                  className={classes.iconTile}
                  style={{
                    background: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    title="heart"
                    style={{
                      width: '50%',
                      height: 'auto',
                      color: '#002d5c',
                    }}
                    icon={['fad', 'house-user']}
                  />
                </div>
              </div>
              {/* Sixth Row   */}
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic22} alt="person 16" />
              </div>
              <div className={classes.tile}>
                <div
                  className={classes.iconTile}
                  style={{
                    background: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    title="heart"
                    style={{
                      width: '50%',
                      height: 'auto',
                      color: '#055291',
                    }}
                    icon={['fad', 'lightbulb-on']}
                  />
                </div>
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic24} alt="person 17" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic25} alt="person 18" />
              </div>
              <div className={classes.tile}>
                <div
                  className={classes.iconTile}
                  style={{
                    background: '#2a7abc',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    title="heart"
                    style={{
                      width: '50%',
                      height: 'auto',
                      color: '#fff',
                    }}
                    icon={['fad', 'hand-fist']}
                  />
                </div>
              </div>

              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic27} alt="person 19" />
              </div>
              {/* Seventh Row   */}
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic28} alt="person 20" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic29} alt="person 21" />
              </div>
              <div className={classes.tile}>
                <div
                  className={classes.iconTile}
                  style={{
                    background: '#002d5c',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    title="heart"
                    style={{
                      width: '50%',
                      height: 'auto',
                      color: '#fff',
                    }}
                    icon={['fad', 'hands-holding-circle']}
                  />
                </div>
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic31} alt="person 22" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic32} alt="person 23" />
              </div>
              <div className={classes.tile}>
                <img className={classes.tileImg} src={mosaic33} alt="person 24" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" color="primary" className={classes.header}>
              Join our Remote-First Global Work Community
            </Typography>
            <Typography variant="body1" className={classes.content}>
              WorkWave provides an innovative and dynamic remote-first Global Work Community that
              encourages growth, creativity, and collaboration. No matter what stage of your career,
              WorkWave is your place to be part of a global company with a startup feel, where your
              ideas matter and your growth is a priority.
            </Typography>
            <Typography variant="body1" color="primary" className={classes.rideWave} role="heading">
              Join us and #RideTheWave
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <WaveDownSVG height="213" width="100%" fill="white" />
    </div>
  );
};

export default Intro;
