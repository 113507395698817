import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Grid, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Element } from 'react-scroll';

import { Seo, Hero, Lazy } from '../components';
import Intro from '../components/intro';
import Perks from '../components/perks';
import { Testimonials } from '../components/testimonials';
import ByTheNumbers from '../components/byTheNumbers';
import Awards from '../components/awards';
import CoreValues from '../components/coreValues';
import { Interview } from '../components/interview';
import WorkwaveCares from '../components/workwaveCares';
import Locations from '../components/locations';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { FC } from '../util';

const IndexPage: FC = () => {
  return (
    <>
      <Seo title="Home" />

      <Element>
        <Hero />
      </Element>

      <Intro />
      <WaveDownSVG height="213" width="100%" fill="white" />

      <Element name="perks">
        <Perks />
        <WaveUpSVG height="213" width="100%" fill="white" />
      </Element>

      <Testimonials />

      <Element name="numbers">
        <ByTheNumbers />
      </Element>

      <Awards />

      <Element name="values">
        <CoreValues />
      </Element>

      <Element name="interview">
        <Interview />
      </Element>

      <WaveDownSVG height="213" width="100%" fill="white" shadow />
      <WorkwaveCares />

      <WaveUpSVG height="213" width="100%" fill="white" shadow />
      
      <Locations />
    </>
  );
};

export default IndexPage;
