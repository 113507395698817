import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography, Grid, makeStyles, Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FC } from '../../../util';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: '6rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
  },
  perkCont: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    minHeight: '145px',
    marginTop: '1.5rem',
    //marginBottom: '1.5rem',
  },
  fontColor: {
    color: '#4B5B69',
    lineHeight: 1.2,
    animation: '$fadeIn 1s',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      animation: 'none',
      fontSize: '1rem',
    },
  },
  backgroundIcon: {
    backgroundColor: '#fff',
    border: '2px solid #E5EAF4',
    boxShadow: '0 0px 20px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: '75px',
    height: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
    animation: '$fadeIn 1s',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 100,
    },
  },
}));

const ItalyBenefits: FC = () => {
  const classes = useStyles();
  const med = useMediaQuery('(max-width: 960px)');
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      spacing={2}
      className={classes.root}
    >
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="generous holiday entitlement"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#002D5C',
              }}
              icon={['fad', 'calendar']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Generous
            <br />
            Holiday entitlement
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="additional 5 days of flexible time off"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#2a7abc',
              }}
              icon={['fad', 'island-tropical']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Additional 5 days <br />
            of Flexible Time Off
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="calm app subscription"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#0f95a4',
              }}
              icon={['fad', 'moon-cloud']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Calm App <br />
            Subscription
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="EAP Program"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#055291',
              }}
              icon={['fad', 'circle-heart']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            EAP Program
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Personal Travel Discounts"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#0f95a4',
              }}
              icon={['fad', 'plane']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Personal Travel <br />
            Discounts
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Paid time off for volunteering"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#002D5C',
              }}
              icon={['fad', 'hand-holding-heart']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Paid Time Off <br />
            for Volunteering
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Employee Discount Scheme"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#055291',
              }}
              icon={['fad', 'badge-percent']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Employee
            <br />
            Discount Scheme
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={6} md={3}>
        <Grid className={classes.perkCont}>
          <div className={classes.backgroundIcon}>
            <FontAwesomeIcon
              title="Employee Referral Bonus"
              style={{
                height: med ? 'auto' : '36px',
                width: med ? 'auto' : '36px',
                maxWidth: '36px',
                color: '#2a7abc',
              }}
              icon={['fad', 'sack-dollar']}
            />
          </div>
          <Typography variant="body1" className={classes.fontColor}>
            Employee <br />
            Referral Bonuses
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ItalyBenefits;
