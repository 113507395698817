import React, { useState, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';

import {
  Grid,
  Container,
  Typography,
  Card,
  CardMedia,
  useMediaQuery,
  easing,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import image1 from '../../images/workwaveCares/wwc-image1.jpg';
import image2 from '../../images/workwaveCares/wwc-image2.jpg';
import image3 from '../../images/workwaveCares/wwc-image3.jpg';
import image4 from '../../images/workwaveCares/wwc-image4.jpg';
import image5 from '../../images/workwaveCares/wwc-image5.jpg';
import image6 from '../../images/workwaveCares/wwc-image6.jpg';
import image7 from '../../images/workwaveCares/wwc-image7.jpg';
import image8 from '../../images/workwaveCares/wwc-image8.jpg';
import image9 from '../../images/workwaveCares/wwc-image9.jpg';
import image10 from '../../images/workwaveCares/wwc-image10.jpg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F5F9FF',
    padding: '14rem 0 12rem 0',

    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: '10rem 0 8rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10rem 0 10rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8rem 0 8rem 0',
    },
  },
  linkTextSocial: {
    opacity: '1',
    transition: 'opacity .25s ease-in-out',
    '&:hover': {
      opacity: '0.6',
    },
  },
  header: {
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.3rem',
    },
  },
  icon: {
    opacity: 0.5,
    transition: 'all .3s',
    color: '#002D5C',
    '&:hover': {
      transform: 'scale(1.04)',
      opacity: 1,
    },
  },
  subHeader: {
    paddingTop: '2em',
    fontFamily: 'Roboto',
    //fontSize: '1.375rem',
    color: '#4B5B69',
    textAlign: 'center',
  },

  slideItem: {
    background: 'blue',
    height: '200px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '20px',
    border: '2px solid #E5EAF4',
    boxShadow: '0 6px 10px 0px rgba(0, 0, 0, 0.15)',
  },

  slidePadding: {
    padding: '2rem 10px',
  },

  // nextArrow: {
  //   position: 'absolute',
  //   right: '-35px',
  //   color: 'white',
  //   bottom: '43%',
  //   opacity: 0.6,
  //   cursor: 'pointer',
  //   transition: 'all .5s',
  //   '&:hover': {
  //     opacity: 1,
  //   },
  //   [theme.breakpoints.down('lg')]: { right: '-15px' },
  // },
  // prevArrow: {
  //   position: 'absolute',
  //   left: '-35px',
  //   color: 'white',
  //   top: '50%',
  //   opacity: 0.6,
  //   cursor: 'pointer',
  //   transition: 'all .5s',
  //   '&:hover': {
  //     opacity: 1,
  //   },
  //   [theme.breakpoints.down('lg')]: { left: '-15px' },
  // },
}));

const WorkwaveCares = () => {
  const classes = useStyles();
  const md = useMediaQuery('(max-width: 1020px)');

  let settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 8,
    className: 'center',
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    easing: 'none',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h2" color="primary" className={classes.header}>
                #WorkWaveCares
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body1" className={classes.subHeader}>
                Supporting the communities around which we live and work is extremely important to
                us. Every year, WorkWave actively participates in charitable causes and events.
                Check out some WorkWavers in action participating in our monthly Flag challenge,
                where the winner gets a donation made in their name to the charity of their choice
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <div style={{ padding: '2rem 0 2rem 0' }}>
          <Slider {...settings}>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image1})` }}
                ></div>
              </div>
            </div>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image2})` }}
                ></div>
              </div>
            </div>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image3})` }}
                ></div>
              </div>
            </div>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image4})` }}
                ></div>
              </div>
            </div>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image5})` }}
                ></div>
              </div>
            </div>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image6})` }}
                ></div>
              </div>
            </div>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image7})` }}
                ></div>
              </div>
            </div>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image8})` }}
                ></div>
              </div>
            </div>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image9})` }}
                ></div>
              </div>
            </div>
            <div>
              <div className={classes.slidePadding}>
                <div
                  className={classes.slideItem}
                  role="img"
                  style={{ backgroundImage: `url(${image10})` }}
                ></div>
              </div>
            </div>
          </Slider>
        </div>

        <Container>
          {md ? (
            <Grid container direction="column" justifyContent="center">
              <Typography variant="h4" color="primary" style={{ textAlign: 'center' }}>
                We're an <span style={{ color: '#2A7ABC' }}>Engaged Employer</span> on
              </Typography>
              <Grid item container justifyContent="center">
                <StaticImage
                  src="../../images/workwaveCares/glassdoor-logo.png"
                  alt="glassdoor logo"
                  style={{ marginTop: '10px', marginBottom: '2rem', maxWidth: '150px' }}
                />
              </Grid>

              <Typography variant="h4" color="primary" style={{ textAlign: 'center' }}>
                Check out <span style={{ color: '#2A7ABC' }}>#LifeatWorkWave</span>
              </Typography>
              <Grid item container justifyContent="center" style={{ marginTop: '10px' }}>
                <a
                  className={classes.linkTextSocial}
                  href="https://www.facebook.com/WorkWaveCo/"
                  target="_blank"
                  aria-label="Opens in a new tab"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <FontAwesomeIcon
                    title="facebook"
                    style={{
                      height: '35px',
                      width: '35px',
                      maxWidth: '35px',
                      color: '#002D5C',
                    }}
                    icon={['fab', 'square-facebook']}
                  />
                </a>
                <a
                  className={classes.linkTextSocial}
                  href="https://twitter.com/WorkWave/"
                  target="_blank"
                  aria-label="Opens in a new tab"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <FontAwesomeIcon
                    title="twitter"
                    style={{
                      height: '35px',
                      width: '35px',
                      maxWidth: '35px',
                      color: '#002D5C',
                      marginLeft: '5px',
                    }}
                    icon={['fab', 'square-x-twitter']}
                  />
                </a>
                <a
                  className={classes.linkTextSocial}
                  href="https://www.instagram.com/workwave/"
                  target="_blank"
                  aria-label="Opens in a new tab"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <FontAwesomeIcon
                    title="instagram"
                    style={{
                      height: '35px',
                      width: '35px',
                      maxWidth: '35px',
                      color: '#002D5C',
                      marginLeft: '5px',
                    }}
                    icon={['fab', 'square-instagram']}
                  />
                </a>
                <a
                  className={classes.linkTextSocial}
                  href="https://www.youtube.com/user/MarathonDataLLC/"
                  target="_blank"
                  aria-label="Opens in a new tab"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <FontAwesomeIcon
                    title="youtube"
                    style={{
                      height: '35px',
                      width: '35px',
                      maxWidth: '35px',
                      color: '#002D5C',
                      marginLeft: '9px',
                    }}
                    icon={['fab', 'square-youtube']}
                  />
                </a>
                <a
                  className={classes.linkTextSocial}
                  href="https://www.linkedin.com/company/workwave/"
                  target="_blank"
                  aria-label="Opens in a new tab"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <FontAwesomeIcon
                    title="linkedin"
                    style={{
                      height: '35px',
                      width: '35px',
                      maxWidth: '35px',
                      color: '#002D5C',
                      marginLeft: '5px',
                    }}
                    icon={['fab', 'linkedin']}
                  />
                </a>
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent="space-between" style={{ padding: '0 0 6rem 0' }}>
              <Grid
                item
                // container
                // justifyContent={md ? 'center' : 'flex-start'}
                style={{ paddingBottom: md && '2rem' }}
              >
                <Typography variant="h4" color="primary" style={{ textAlign: md && 'center' }}>
                  We're an <span style={{ color: '#2A7ABC' }}>Engaged Employer</span> on
                </Typography>
                <StaticImage
                  src="../../images/workwaveCares/glassdoor-logo.png"
                  alt="glassdoor logo"
                  style={{ marginTop: '10px' }}
                />
              </Grid>

              <Grid item>
                <Typography variant="h4" color="primary">
                  Check out <span style={{ color: '#2A7ABC' }}>#LifeatWorkWave</span>
                </Typography>
                <Grid item container justifyContent="flex-end" style={{ marginTop: '10px' }}>
                  <a
                    className={classes.linkTextSocial}
                    href="https://www.facebook.com/WorkWaveCo/"
                    target="_blank"
                    aria-label="Opens in a new tab"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title="facebook"
                      style={{
                        height: '35px',
                        width: '35px',
                        maxWidth: '35px',
                        color: '#002D5C',
                      }}
                      icon={['fab', 'square-facebook']}
                    />
                  </a>
                  <a
                    className={classes.linkTextSocial}
                    href="https://twitter.com/WorkWave/"
                    target="_blank"
                    aria-label="Opens in a new tab"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title="twitter"
                      style={{
                        height: '35px',
                        width: '35px',
                        maxWidth: '35px',
                        color: '#002D5C',
                        marginLeft: '5px',
                      }}
                      icon={['fab', 'square-x-twitter']}
                    />
                  </a>
                  <a
                    className={classes.linkTextSocial}
                    href="https://www.instagram.com/workwave/"
                    target="_blank"
                    aria-label="Opens in a new tab"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title="instagram"
                      style={{
                        height: '35px',
                        width: '35px',
                        maxWidth: '35px',
                        color: '#002D5C',
                        marginLeft: '5px',
                      }}
                      icon={['fab', 'square-instagram']}
                    />
                  </a>
                  <a
                    className={classes.linkTextSocial}
                    href="https://www.youtube.com/user/MarathonDataLLC/"
                    target="_blank"
                    aria-label="Opens in a new tab"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title="youtube"
                      style={{
                        height: '35px',
                        width: '35px',
                        maxWidth: '35px',
                        color: '#002D5C',
                        marginLeft: '9px',
                      }}
                      icon={['fab', 'square-youtube']}
                    />
                  </a>
                  <a
                    className={classes.linkTextSocial}
                    href="https://www.linkedin.com/company/workwave/"
                    target="_blank"
                    aria-label="Opens in a new tab"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <FontAwesomeIcon
                      title="linkedin"
                      style={{
                        height: '35px',
                        width: '35px',
                        maxWidth: '35px',
                        color: '#002D5C',
                        marginLeft: '5px',
                      }}
                      icon={['fab', 'linkedin']}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
    </>
  );
};

export default WorkwaveCares;
