// import authentic from '../../images/coreValues/core-values_authentic.svg';
// import difference from '../../images/coreValues/core-values_difference.svg';
// import embrace from '../../images/coreValues/core-values_embrace.svg';
// import empower from '../../images/coreValues/core-values_empower.svg';
// import impact from '../../images/coreValues/core-values_impact.svg';

export const values = [
  {
    id: 1,
    title: 'Be Authentic',
    body: 'We believe in the act of being genuine, living and leading with transparency and integrity, communicating openly and honestly, and building strong, trusting foundations.',
    // img: authentic,
    color: '#4087C1',
  },
  {
    id: 2,
    title: 'Be An Impact Player',
    body: 'Everyone, no matter what their position, can make significant contributions as an individual, but also positively impact those around them.',
    // img: impact,
    color: '#6F7881',
  },
  {
    id: 3,
    title: 'Make a Difference',
    body: 'We are committed to going the extra mile and going beyond service and to build a better and more sustainable world.',
    // img: difference,
    color: '#30446A',
  },
  {
    id: 4,
    title: 'Empower Yourself & Others',
    body: 'We achieve more when we collaborate and all work together, keeping people at the core and our customers first.',
    // img: empower,
    color: '#249FAD',
  },
  {
    id: 5,
    title: 'Embrace Opportunities',
    body: 'Each of us is responsible for owning our full potential and helping our company be a leader in the industry.',
    // img: embrace,
    color: '#1D649B',
  },
];
