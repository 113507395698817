import React, { useState } from 'react';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

import Authentic from '../../../svgs/coreValues/core-values_authentic.svg';
import Difference from '../../../svgs/coreValues/core-values_difference.svg';
import Embrace from '../../../svgs/coreValues/core-values_embrace.svg';
import Empower from '../../../svgs/coreValues/core-values_empower.svg';
import Impact from '../../../svgs/coreValues/core-values_impact.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: '1rem',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },
  paraContent: {
    fontFamily: ['Roboto', 'sans-serif'].join(),
    zIndex: 100,
    position: 'relative',
    textAlign: 'left',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('xs')]: {},
  },

  subHeader: {
    color: '#002D5C',
    fontSize: '2rem',
    fontWeight: 700,
    textAlign: 'center',
    paddingBottom: '0rem',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
}));

export const MobileFeatures = ({ values }) => {
  const classes = useStyles();
  const [carouselOptions] = useState({
    autoPlay: true,
    animation: 'slide',
    indicators: false,
    timeout: 500,
    interval: 5000,
    navButtonsAlwaysVisible: true,
    // navButtonsAlwaysInvisible: true,
  });

  const selectedImage = (id) => {
    switch (id) {
      case 1:
        return <Authentic />;
      case 2:
        return <Impact />;
      case 3:
        return <Difference />;
      case 4:
        return <Empower />;
      case 5:
        return <Embrace />;
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      style={{ paddingTop: '2rem' }}
    >
      <Grid item>
        <Typography variant="h2" className={classes.header}>
          Our Core Values
        </Typography>

      

        <Typography variant="body1" className={classes.paraContent}>
          At WorkWave, we celebrate uniqueness and diversity while cherishing shared 'Core Values'
          that unite us all. These principles drive our success, guiding every decision and shaping
          our vibrant culture. Together, we're creating an engaging workplace and a close-knit team,
          all fueled by these values as we reach our full potential.
        </Typography>

        <Typography variant="h2" className={classes.subHeader}>
              Join us and Discover Your <span style={{ color: '#2F7FC1' }}>Full Potential</span>
            </Typography>
        <Carousel
          style={{ width: '100%' }}
          autoPlay={carouselOptions.autoPlay}
          animation={carouselOptions.animation}
          indicators={carouselOptions.indicators}
          indicatorContainerProps={{
            style: {
              marginBottom: '16px',
            },
          }}
          interval={carouselOptions.interval}
          timeout={carouselOptions.timeout}
          navButtonsAlwaysVisible={carouselOptions.navButtonsAlwaysVisible}
          navButtonsAlwaysInvisible={carouselOptions.navButtonsAlwaysInvisible}
          navButtonsProps={{
            style: {
              backgroundColor: '#002D5C',
              marginBottom: '5rem',
              padding: '5px',
            },
          }}
        >
          {values.map((card, index) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '380px',
              }}
              key={index}
            >
              {selectedImage(card.id)}
              <Typography
                variant="h5"
                style={{
                  color: '#002D5C',
                  textAlign: 'center',
                }}
              >
                {card.title}
              </Typography>
              <Typography
                variant="body1"
                className={classes.cardBody}
                style={{ textAlign: 'center' }}
              >
                {card.body}
              </Typography>
            </div>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};
