import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Container, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { PerksBody } from './PerksBody';
import { MobilePerks } from './MobilePerks';

import RibbonCard from './RibbonCard';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#f5f9ff',
    padding: '6rem 0 12rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 0 8rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4rem 0 3rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0 1rem 0',
    },
  },
  subHeader: {
    paddingTop: '8rem',
    fontFamily: 'Roboto',
    fontSize: '1.3125rem',
    fontWeight: 500,
    textAlign: 'center',
    color: '#4B5B69',
    paddingBottom: '10px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '7rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  header: {
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    color: '#002D5C',
    paddingBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      fontSize: '1.9rem',
      paddingBottom: '40px',
    },
  },
  hightlight: {
    color: '#0F95A4',
    textTransform: 'uppercase',
  },
  paraContent: {
    color: '#4B5B69',
    textAlign: 'center',
    paddingBottom: '5rem',
    fontFamily: ['Roboto', 'sans-serif'].join(),
  },
  // tabRoot: {
  //   flexGrow: 1,
  //   marginTop: '4rem',
  //   marginBottom: '4rem',
  // },
  appBar: {
    padding: '0px',
    background: '#fff',
    color: '#002D5C',
    boxShadow: 'none',
  },
  tabContainer: {
    borderBottom: `6px solid rgba(0,0,0,0.1)`,
    justifyContent: 'space-between',
  },
  tabText: {
    fontSize: 24,
    textDecoration: 'none',
    fontWeight: 'bold',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
    minWidth: 'auto',
    padding: '25px 0',
  },
  [theme.breakpoints.down('sm')]: {
    tabText: {
      marginRight: '30px',
      fontSize: '1.15rem',
      '& > div:nth-child(last)': {
        marginRight: '0px',
      },
    },
  },
  card: {
    // border: '1px solid #ECECEE',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
    borderRadius: '8px',
    cursor: 'pointer',
    background: '#FFFFFF',
    width: '100%',
    transition: 'transform 0.25s, box-shadow 0.75s',
    margin: '0 auto',
    position: 'relative',
    border: '2px solid #E5EAF4',
    paddingRight: '30px',
    '&:last-of-type': {
      paddingRight: '0px',
    },
  },
  icons: {
    height: '50px',
    width: '50px',
    border: `1px solid #E5EAF4`,
    background: '#E5EAF4',
    padding: '6px',
  },
}));

const Perks = () => {
  const classes = useStyles();

  const ribbons = [
    {
      ribbonTitle: 'US Benefits',
    },
    {
      ribbonTitle: 'UK Benefits',
    },
    {
      ribbonTitle: 'Italy Benefits',
    },
    {
      ribbonTitle: 'AUS Benefits',
    },
  ];

  const [value, setValue] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  const handleClick = (event, newValue) => {
    setTransitioning(true);
    setValue(newValue);
    setTransitioning(false);
  };

  const med = useMediaQuery('(max-width: 960px)');
  return (
    <div className={classes.root}>
      <Container>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} md={10}>
            <Typography variant="body1" className={classes.subHeader} role="heading">
              PERKS & BENEFITS
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="h2" className={classes.header}>
              Here Are Some Ways <br />
              We <span style={{ color: '#0F95A4' }}>Invest</span> in Our Employees Around the Globe:
            </Typography>
          </Grid>
        </Grid>
        <>
          <Grid container direction="row" justifyContent="space-between">
            {ribbons.map((ribbon, index) => (
              <RibbonCard
                handleClick={handleClick}
                ribbon={ribbon}
                index={index}
                value={value}
                tabIndex={0}
                role="button"
              />
            ))}
          </Grid>
          <PerksBody value={value} transitioning={transitioning} />
        </>
      </Container>
    </div>
  );
};

export default Perks;
