import React from 'react';
import { Grid, Container, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import image1 from '../../images/testimonials/testimonial-kristiana.jpg';
import image2 from '../../images/testimonials/testimonial-hosam.jpg';
import image3 from '../../images/testimonials/testimonial-britt.jpg';
import image4 from '../../images/testimonials/testimonial-dan.jpg';
import image5 from '../../images/testimonials/testimonial-pedro.jpg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles((theme) => ({
  slickNext: {
    display: 'none !important',
  },

  root: {
    position: 'relative',
    zIndex: '3',
    marginBottom: '30px',
    [theme.breakpoints.down('md')]: {},
  },
  header: {
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'center',
    color: '#002D5C',
    paddingBottom: '0px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
      marginTop: '1rem',
    },
  },
  hightlight: {
    color: '#0F95A4',
  },
  paraContent: {
    color: '#4B5B69',
    textAlign: 'center',
    paddingBottom: '3rem',
    fontFamily: ['Roboto', 'sans-serif'].join(),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '0rem',
      textAlign: 'left',
    },
  },

  slideItem: {
    background: 'blue',
    height: '450px',
    width: '60%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '20px',
    position: 'relative',
    float: 'right',
    margin: '50px 10px 12px 0',
    border: '2px solid #E5EAF4',
    boxShadow: '0 6px 10px 0px rgba(0, 0, 0, 0.15)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'relative',
      height: '450px',
      zIndex: '2',
      margin: '0 0 30px 0',
      borderRadius: '20px 20px 0 0',
      border: 'none',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: '300px',
    },
  },

  slidePadding: {
    padding: '0',
    position: 'relative',
  },

  slideCard: {
    padding: '30px',
    position: 'absolute',
    marginLeft: '10px',
    top: '0',
    background: '#fff',
    width: '50%',
    left: '0',
    borderRadius: '20px',
    border: '2px solid #E5EAF4',
    boxShadow: '0 6px 10px 0px rgba(0, 0, 0, 0.15)',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      width: '100%',
      position: 'relative',
      zIndex: '1',
      boxShadow: 'none',
    },
  },
  slideCardText: {
    color: '#4B5B69',
    fontSize: '1.75rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '400;',
    margin: '0 0 20px 0',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  },

  beginQuote: {
    color: '#002D5C',
    height: '40px',
    width: 'auto',
  },

  arrowLeft: {
    color: '#002D5C',
    height: '33px',
    width: '33px',
  },
  arrowRight: {
    color: '#002D5C',
    height: '33px',
    width: '33px',
  },

  customPrevArrow: {
    position: 'absolute !important',
    transition: 'opacity 0.3s ease',
    opacity: '0.7',
    fontSize: '12px',
    top: '100% !important',
    left: '50% !important',
    right: 'auto !important',
    marginTop: '20px',
    marginLeft: '-60px',
    zIndex: 3,
    '&:hover': {
      opacity: '1',
    },
    '&::before': {
      content: "''",
      display: 'none !important',
    },

    [theme.breakpoints.down('md')]: {
      marginLeft: '-60px',
      top: '100% !important',
      left: '50% !important',
    },
  },

  customNextArrow: {
    position: 'absolute !important',
    transition: 'opacity 0.3s ease',
    opacity: '0.7',
    fontSize: '12px',
    top: '100% !important',
    left: '50% !important',
    right: 'auto !important',
    marginTop: '20px',
    marginLeft: '20px',
    zIndex: 3,
    '&:hover': {
      opacity: '1',
    },
    '&::before': {
      content: "''",
      display: 'none !important',
    },

    [theme.breakpoints.down('md')]: {
      top: '100% !important',
      left: '50% !important',
      marginLeft: '20px',
    },
  },
}));

export const Testimonials = () => {
  const classes = useStyles();

  const slider = React.useRef(null);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    const classes = useStyles();
    return (
      <div
        className={`custom-next-arrow ${className} ${classes.customNextArrow}`}
        aria-label="next arrow for slider"
        role="button"
        tabIndex={0}
        style={{ ...style }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={['fas', 'arrow-right']} className={classes.arrowRight} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    const classes = useStyles();
    return (
      <div
        className={`custom-prev-arrow ${className} ${classes.customPrevArrow}`}
        aria-label="next arrow for slider"
        role="button"
        tabIndex={0}
        style={{ ...style }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={['fas', 'arrow-left']} className={classes.arrowLeft} />
      </div>
    );
  }

  let settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    waitForAnimate: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="center" spacing={2}>
        <Grid item xs={12} md={10}>
          <Typography variant="h2" className={classes.header}>
            We{' '}
            <span className={classes.hightlight} style={{ color: '#055291' }}>
              Are
            </span>{' '}
            WorkWave
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="body1" className={classes.paraContent}>
            We thrive in a culture that values and celebrates diversity and inclusion. When our
            teams come together with our unique experiences and diverse perspectives, anything is
            possible!
          </Typography>
        </Grid>
      </Grid>

      <div style={{ padding: '2rem 0 2rem 0' }}>
        <Slider ref={slider} {...settings}>
          <div>
            <div className={classes.slidePadding}>
              <div
                className={classes.slideItem}
                style={{ backgroundImage: `url(${image1})` }}
                role="img"
                alt="employee 1"
              ></div>
              <div className={classes.slideCard}>
                <FontAwesomeIcon icon={['fad', 'quote-right']} className={classes.beginQuote} />
                <p className={classes.slideCardText} role="heading">
                  The flexibility to work from home has transformed my work life balance and a
                  supportive manager along with a collaborative team truly make all the difference
                  in fostering a positive and productive work environment at WorkWave
                </p>
                <p role="heading"
                  style={{
                    color: '#002D5C',
                    fontSize: '1.25rem',
                    fontWeight: '700',
                    margin: '0',
                    display: 'flex',
                  }}
                >
                  <span style={{ color: '#4B5B69', fontWeight: '600', marginRight: '5px' }}>—</span>{' '}
                  Kristiana Ranieri, Senior Talent Acquisition Specialist
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.slidePadding}>
              <div
                className={classes.slideItem}
                style={{ backgroundImage: `url(${image2})` }}
                role="img"
                alt="employee 2"
              ></div>
              <div className={classes.slideCard}>
                <FontAwesomeIcon icon={['fad', 'quote-right']} className={classes.beginQuote} />
                <p className={classes.slideCardText} role="heading">
                  At WorkWave, the people are the best part. The diversity, commitment and the level
                  of experience are vast! Every day brings new challenges, keeping work interesting.
                  Meeting my WorkWave family has led to lasting friendships beyond the workplace.”
                </p>
                <p role="heading"
                  style={{
                    color: '#002D5C',
                    fontSize: '1.25rem',
                    fontWeight: '700',
                    margin: '0',
                    display: 'flex',
                  }}
                >
                  <span style={{ color: '#4B5B69', fontWeight: '600', marginRight: '5px' }}>—</span>{' '}
                  Hosam Sayed, Sales Manager, Emerging Markets
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.slidePadding}>
              <div
                className={classes.slideItem}
                style={{ backgroundImage: `url(${image3})` }}
                role="img"
                alt="employee 3"
              ></div>
              <div className={classes.slideCard}>
                <FontAwesomeIcon icon={['fad', 'quote-right']} className={classes.beginQuote} />
                <p className={classes.slideCardText} role="heading">
                  I am surrounded by a team of creative and passionate individuals, and when we come
                  together we are able to come up with ideas and strategies that go beyond what I
                  have always known, which has helped me grow tremendously in my career over the
                  past year.”
                </p>
                <p role="heading"
                  style={{
                    color: '#002D5C',
                    fontSize: '1.25rem',
                    fontWeight: '700',
                    margin: '0',
                    display: 'flex',
                  }}
                >
                  <span style={{ color: '#4B5B69', fontWeight: '600', marginRight: '5px' }}>—</span>{' '}
                  Brittany Boyle, Director, Strategic Communications
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.slidePadding}>
              <div
                className={classes.slideItem}
                style={{ backgroundImage: `url(${image4})` }}
                role="img"
                alt="employee 4"
              ></div>
              <div className={classes.slideCard}>
                <FontAwesomeIcon icon={['fad', 'quote-right']} className={classes.beginQuote} />
                <p className={classes.slideCardText} role="heading">
                  At WorkWave, I’m able to solve challenges in a collaborative environment. It has
                  helped me become a better developer, and now a better leader. I enjoy working with
                  a team that is eager to speak up to help solve the challenges we face on a daily
                  basis.”
                </p>
                <p role="heading"
                  style={{
                    color: '#002D5C',
                    fontSize: '1.25rem',
                    fontWeight: '700',
                    margin: '0',
                    display: 'flex',
                  }}
                >
                  <span style={{ color: '#4B5B69', fontWeight: '600', marginRight: '5px' }}>—</span>{' '}
                  Dan Lischak, Senior Manager, Engineering
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.slidePadding}>
              <div
                className={classes.slideItem}
                style={{ backgroundImage: `url(${image5})` }}
                role="img"
                alt="employee 5"
              ></div>
              <div className={classes.slideCard}>
                <FontAwesomeIcon icon={['fad', 'quote-right']} className={classes.beginQuote} />
                <p className={classes.slideCardText} role="heading">
                  Leading a diverse team of product managers, I've nurtured a collaborative and
                  innovative environment. With varied backgrounds and specialized skills, this
                  setting allows my team to exceed expectations by tackling challenges with
                  strategic and tactical solutions.
                </p>
                <p role="heading"
                  style={{
                    color: '#002D5C',
                    fontSize: '1.25rem',
                    fontWeight: '700',
                    margin: '0',
                    display: 'flex',
                  }}
                >
                  <span style={{ color: '#4B5B69', fontWeight: '600', marginRight: '5px' }}>—</span>{' '}
                  Pedro Cutino, Sr. Director of Product
                </p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </Container>
  );
};
